<template>
  <main id="reports">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-orange">Übersicht</h2>
        <h1 style="padding-left: 4px;">Umsatzberichte</h1>
        <div class="grid-12">
          <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <h3 style="padding-top: 2px;">Umsatz im aktuellen Monat</h3>
              <div class="pad-8"></div>
              <div class="pad-40 t-center br-10 bgcolor-lightgrey">
                <div class="pad-40"></div>
                <div class="pad-40"></div>
                <p>Hier folgt eine Grafik zum Umsatz</p>
                <div class="pad-40"></div>
                <div class="pad-40"></div>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
            <div class="bgcolor-orange color-white pad-16 br-10">
              <h3 style="padding-top: 2px; font-size: 1.8em;">Gesamt-Umsatz</h3>
              <div class="t-right">
                <h5 class="f-bold" style="font-size: 2em;">423.048€</h5>
              </div>
              <div class="grid-12" style="margin-top: 8px;">
                <div class="col-sp-6 row-sp-12">
                  <h6>Ticketverkäufe</h6>
                </div>
                <div class="col-sp-6 row-sp-12 t-right">
                  <h6 class="f-bold">211.040€</h6>
                </div>
              </div>
              <div class="grid-12" style="margin-top: 8px;">
                <div class="col-sp-6 row-sp-12">
                  <h6>Getränke / Speisen</h6>
                </div>
                <div class="col-sp-6 row-sp-12 t-right">
                  <h6 class="f-bold">212.008€</h6>
                </div>
              </div>
            </div>
            <div class="pad-4"></div>
            <div class="bgcolor-white pad-16 br-10">
              <h3 style="padding-top: 2px; margin-bottom: 24px;">Umsätze der letzten Monate</h3>
              <div class="grid-12" style="margin-top: 8px;">
                <div class="col-sp-6 row-sp-12">
                  <h6>September 2022</h6>
                </div>
                <div class="col-sp-4 row-sp-12 t-right">
                  <h6 class="f-bold">48.438€</h6>
                </div>
                <div class="col-sp-2 row-sp-12 t-right">
                  <button class="button bgcolor-lightgrey downloadButton color-white">
                    <img src="assets/images/icons/download-orange.svg"/>
                  </button>
                </div>
              </div>
              <div class="grid-12" style="margin-top: 8px;">
                <div class="col-sp-6 row-sp-12">
                  <h6>August 2022</h6>
                </div>
                <div class="col-sp-4 row-sp-12 t-right">
                  <h6 class="f-bold">56.283€</h6>
                </div>
                <div class="col-sp-2 row-sp-12 t-right">
                  <button class="button bgcolor-lightgrey downloadButton color-white">
                    <img src="assets/images/icons/download-orange.svg"/>
                  </button>
                </div>
              </div>
              <div class="grid-12" style="margin-top: 8px;">
                <div class="col-sp-6 row-sp-12">
                  <h6>Juli 2022</h6>
                </div>
                <div class="col-sp-4 row-sp-12 t-right">
                  <h6 class="f-bold">28.923€</h6>
                </div>
                <div class="col-sp-2 row-sp-12 t-right">
                  <button class="button bgcolor-lightgrey downloadButton color-white">
                    <img src="assets/images/icons/download-orange.svg"/>
                  </button>
                </div>
              </div>
              <div class="grid-12" style="margin-top: 8px;">
                <div class="col-sp-6 row-sp-12">
                  <h6>Juni 2022</h6>
                </div>
                <div class="col-sp-4 row-sp-12 t-right">
                  <h6 class="f-bold">34.523€</h6>
                </div>
                <div class="col-sp-2 row-sp-12 t-right">
                  <button class="button bgcolor-lightgrey downloadButton color-white">
                    <img src="assets/images/icons/download-orange.svg"/>
                  </button>
                </div>
              </div>
              <div class="pad-8"></div>
              <button class="button fluid bgcolor-orange color-white">Alle Monatsberichte ansehen</button>
            </div>
          </div>
        </div>
        <div class="pad-4">
          <div class="bgcolor-white pad-16 br-10">
            <div class="grid-12">
              <div class="col-sp-12 col-sd-3 row-sp-12">
                <h3 style="padding-top: 16px; font-size: 1.6em;">Transaktionen</h3>
              </div>
              <div class="col-sp-12 col-sd-9 row-sp-12 t-right">
                <div style="display: inline-block; padding-right: 8px;">
                  <div class="field">
                    <label for="event">Filtern nach Events</label>
                    <select id="events">
                      <option>ABI XXL - Uelzener Nacht</option>
                      <option>Event 2</option>
                      <option>Event 3</option>
                    </select>
                  </div>
                </div>
                <div style="display: inline-block; padding-right: 8px;">
                  <div class="field">
                    <label for="von">Von</label>
                    <input type="date" id="von">
                  </div>
                </div>
                <div style="display: inline-block;">
                  <div class="field">
                    <label for="von">Bis</label>
                    <input type="date" id="bis">
                  </div>
                </div>
              </div>
            </div>
            <section id="transactions">
              <table>
                <thead>
                    <tr>
                      <td>Transaktions-ID</td>
                      <td>Kunde</td>
                      <td>Kategorie</td>
                      <td>Beschreibung</td>
                      <td>Summe</td>
                    </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>#486338</td>
                    <td>Tommy Grimm</td>
                    <td>Ticketverkauf</td>
                    <td>Standardticket - Abi XXL Uelzen</td>
                    <td class="t-right">19,99€</td>
                  </tr>
                  <tr>
                    <td>#565641</td>
                    <td>Felix Baumann</td>
                    <td>Ticketverkauf</td>
                    <td>Premiumticket - Abi XXL Uelzen</td>
                    <td class="t-right">29,99€</td>
                  </tr>
                  <tr>
                    <td>#485616</td>
                    <td>Julian Stefans</td>
                    <td>EventlyPay</td>
                    <td>Getränke</td>
                    <td class="t-right">4,99€</td>
                  </tr>
                  <tr>
                    <td>#546547</td>
                    <td>Lisa Müller</td>
                    <td>Ticketverkauf</td>
                    <td>Standardticket - Abi XXL Uelzen</td>
                    <td class="t-right">19,99€</td>
                  </tr>
                  <tr>
                    <td>#534563</td>
                    <td>Annika Peters</td>
                    <td>EventlyPay</td>
                    <td>Getränke</td>
                    <td class="t-right">9,98€</td>
                  </tr>
                  <tr>
                    <td>#256434</td>
                    <td>Julia Göhrde</td>
                    <td>EventlyPay</td>
                    <td>Getränke</td>
                    <td class="t-right">14,53€</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="t-right">Gesamt:</td>
                    <td class="t-right">3.433€</td>
                  </tr>
                </tfoot>
              </table>
            </section>
            <div class="t-right">
              <button class="button bgcolor-second color-white">
                <img src="assets/images/icons/download-white.svg" style="width: 12px; vertical-align: -4px; margin-right: 8px;"/>
                Bericht herunterladen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Reports",
  title: "Reports",
  components: {

  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {

    }
  },
  mounted() {
    this.config.loading = false

  },
  methods: {

  }
}
</script>

<style>
  main#reports{
    padding-bottom: 80px;
  }
  #reports h1{
    font-size: 2em;
  }
  #reports h3{
    font-size: 1.2em;
  }
  .downloadButton{
    padding: 2px 6px;
    margin: 0;
  }
  .downloadButton img{
    width: 12px;
    vertical-align: middle;
  }
  #reports .field label{
    display: block;
    text-align: left;
    font-size: 0.8em;
    font-weight: 800;
  }
  #reports .field input{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
  #reports .field select{
    display: block;
    width: calc(100%);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
  #reports #transactions{
    overflow-x: scroll;
  }
  #reports table{
    width: 100%;
    margin: 16px 0;
  }
  #reports thead tr,
  #reports tfoot tr{
    background-color: rgba(0,0,0,0.05);
  }
  #reports thead td,
  #reports tfoot td{
    font-weight: bold;
    padding: 8px;
  }
  #reports tbody tr{
    background-color: rgba(0,0,0,0.01);
    border-bottom: 1px solid rgba(0,0,0,0.05);
  }
  #reports tbody td{
    font-weight: normal;
    padding: 8px;
  }
</style>