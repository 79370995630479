<template>
  <div class="content-1400">
    <div class="pad-16">
      <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-orange">Übersicht</h2>
      <h1 style="padding-left: 4px;">Aktuelle Events</h1>
      <div class="grid-12 bgcolor-white pad-16 br-10">
        <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-4">
          <router-link :to="{name: 'EventCreate'}" class="createEventCTA">
            <div class="content">
              <img src="assets/images/icons/plus-white.svg"/>
              Neues Event erstellen
            </div>
          </router-link>
        </div>
        <eventItemsComponent v-for="eitems in eventItems" :eventItems="eitems" :key="eitems.id"/>
      </div>
    </div>
    <div class="pad-16">
      <h1 style="padding-left: 4px;">Deine Entwürfe</h1>
      <div class="grid-12 bgcolor-white pad-16 br-10">
        <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-4">
          <router-link :to="{name: 'EventCreate'}" class="createEventCTA">
            <div class="content">
              <img src="assets/images/icons/plus-white.svg"/>
              Neuen Entwurf erstellen
            </div>
          </router-link>
        </div>
        <drafteventItemsComponent v-for="deitems in drafteventItems" :drafteventItems="deitems" :key="deitems.id"/>
      </div>
    </div>
    <div class="pad-16">
      <h1 style="padding-left: 4px;">Vergangende Events</h1>
      <div class="grid-12 bgcolor-white pad-16 br-10">
        <pasteventItemsComponent v-for="peitems in pasteventItems" :pasteventItems="peitems" :key="peitems.id"/>
      </div>
    </div>
  </div>
  <div class="pad-32"></div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import eventItemsComponent from "@/components/events/event";
import drafteventItemsComponent from "@/components/events/draftevent";
import pasteventItemsComponent from "@/components/events/pastevent";

export default {
  name: "EventOverview",
  title: 'Events',
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  components: {
    eventItemsComponent,
    drafteventItemsComponent,
    pasteventItemsComponent
  },
  data(){
    return {
      eventItems: [],
      drafteventItems: [],
      pasteventItems: []
    }
  },
  mounted() {
    this.getEventItems()
    this.getPastEventItems()
    this.getDraftEventItems()
  },
  methods: {
    getEventItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('events/getEventItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.eventItems = response.data
            }
          })
    },
    getPastEventItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('events/getPastEventItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.pasteventItems = response.data
            }
          })
    },
    getDraftEventItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('events/getDraftEventItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.drafteventItems = response.data
            }
          })
    },
  }
}
</script>

<style scoped>
.createEventCTA{
  border: 2px dashed rgba(0,0,0,0.2);
  border-radius: 10px;
  text-align: center;
  font-size: 1em;
  color: rgba(0,0,0,0.6);
  padding: 32px 16px;
  cursor: pointer;
  min-height: 90px;
  margin-top: 10px;
  height: calc(100% - 84px);
  position: relative;
  transition: 0.3s;
  display: block;
}
.createEventCTA .content{
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
.createEventCTA img{
  max-width: 80px;
  display: block;
  margin: 0 auto;
  filter: invert(0.4);
}
.createEventCTA:hover{
  opacity: 0.6;
}
#events h1{
  font-size: 2em;
  margin-bottom: 8px;
}
</style>