<template>
  <main id="promotions">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-orange">Deine</h2>
        <h1 style="padding-left: 4px;">Promotions</h1>
        <div class="grid-12">
          <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <h3 style="padding-top: 2px;">Übersicht</h3>
            </div>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <div class="grid-12">
                <div class="col-sp-8 row-sp-12">
                  <h3 style="padding-top: 2px;">Deine nächsten Events</h3>
                </div>
                <div class="col-sp-4 row-sp-12 t-right">
                  <router-link to="/events/create" class="button bgcolor-orange tiny color-white">Erstellen</router-link>
                </div>
              </div>
              <eventItems v-for="eitems in eventItems" :eventItems="eitems" :key="eitems.id"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import eventItems from "@/components/dashboard/event";

export default {
  name: "Promotions",
  title: "Promotions",
  components: {
    eventItems: eventItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      eventItems: [],
    }
  },
  mounted() {
    this.config.loading = false
    this.geteventItems()
  },
  methods: {
    geteventItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('dashboard/geteventItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.eventItems = response.data
            }
          })
    },
  }
}
</script>

<style>
  #promotions h1{
    font-size: 2em;
  }
  #promotions h3{
    font-size: 1.2em;
  }
</style>