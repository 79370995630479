<template>
  <main id="usermanagement">
    <div class="content-1400">
      <div class="pad-16">
        <div class="grid-12">
          <div class="col-sp-12 col-tb-6 row-sp-12">
            <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-orange">Übersicht</h2>
            <h1 style="padding-left: 4px;">Benutzerverwaltung</h1>
          </div><!--
          <div class="col-sp-12 col-tb-6 row-sp-12 t-right">
            <button class="button bgcolor-orange color-white" @click="createModal = true">Benutzer Erstellen</button>
          </div>-->
        </div>
        <div class="grid-12">
          <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <div class="grid-12">
                <div class="col-sp-12 col-tb-5 row-sp-12">
                  <h3 style="padding-top: 2px;">Übersicht</h3>
                </div>
                <div class="col-sp-12 col-tb-4 row-sp-12">
                    <div class="field">
                      <input style="width: 100%;" type="search" placeholder="Suche nach Benutzern..." v-model="searchUser">
                    </div>
                </div>
                <div class="col-sp-12 col-tb-3 row-sp-12 t-right">
                  <button type="button" @click="this.addUser = !this.addUser" class="button bgcolor-orange color-white">
                    Benutzer anlegen
                  </button>
                </div>
                <div class="col-sp-12 col-tb-12 row-sp-12">
                  <div v-html="addInfo" v-if="addInfo"></div>
                </div>
              </div>
              <div class="pad-8"></div>
              <div id="userOverview">
                <div class="grid-12 f-bold">
                  <div class="col-sp-12 col-tb-3 row-sp-12 pad-8">
                    Name
                  </div>
                  <div class="col-sp-12 col-tb-3 row-sp-12 pad-8">
                    E-Mail
                  </div>
                  <div class="col-sp-12 col-tb-3 row-sp-12 pad-8">
                    Rechtegruppe
                  </div>
                  <div class="col-sp-12 col-tb-3 row-sp-12 pad-8">

                  </div>
                </div>
                <userItems v-for="uitem in userFiltered" :userItem="uitem" :key="uitem.id" :roleItems="roleItems" @update="getUserItems()"/>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <div class="grid-12">
                <div class="col-sp-12 row-sp-12">
                  <h3 style="padding-top: 2px;" class="t-center">Club auswählen</h3>
                </div>
              </div>
              <clubItems v-for="citems in clubItems" :clubItems="citems" :key="citems.id"/>
              <div class="pad-8"></div>
              <router-link to="clubmanager" class="button bgcolor-orange color-white t-center" style="display: block;">Meine Clubs verwalten</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <VueModal id="addUserModal" :show="addUser" approve="Einladen" @approve="invite" @close="addUser = false">
    <h3 style="font-size: 1.3em;" class="t-center">
      Nutzer zum Club
      <span class="color-orange">{{config.getCurrentOrganizer.organizerName}}</span>
      einladen
    </h3>
    <div class="pad-16"></div>
    <div class="t-left">
      <div class="field">
        <label>E-Mail-Adresse</label>
        <input type="email" placeholder="Email-Adresse" v-model="email" @keyup.enter="invite">
      </div>
      <div class="field">
        <label>Rechtegruppe</label>
        <select v-model="roleId">
          <option v-for="(role, index) in roleItems" :key="index" :value="role.id">{{role.title}}</option>
        </select>
      </div>
    </div>
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import clubItems from "@/components/profil/club";
import userItems from "@/components/usermanagement/user";


export default {
  name: "Benutzerverwaltung",
  title: "Benutzerverwaltung",
  components: {
    clubItems,
    userItems
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      clubItems: [],
      userItems: [],
      addUser: false,
      addInfo: false,
      email: '',
      roleId: 1,
      roleItems: '',
      searchUser: ''
    }
  },
  mounted() {
    this.config.loading = false
    if(this.$route.query.invite){
      this.acceptInvite()
    }else{
      this.getClubItems()
      this.getUserItems()
      this.getRoleItems()
    }
  },
  computed: {
    userFiltered(){
      return this.userItems.filter(user => {
        return user.organizerId == this.config.organizerId &&  (user.email.toLowerCase().includes(this.searchUser.toLowerCase()) || user.firstname.toLowerCase().includes(this.searchUser.toLowerCase()) || user.lastname.toLowerCase().includes(this.searchUser.toLowerCase()))
      }).sort((a, b) => {
        if ( a.firstname < b.firstname ){
          return -1;
        }
        if ( a.firstname > b.firstname ){
          return 1;
        }
        return 0;
      })
    }
  },
  methods: {
    acceptInvite(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('token', this.$route.query.invite)
      this.axios.post('usermanagement/acceptInvite', fD)
          .then((response) => {
            if(response.status === 250){
              _this.addInfo = 'Du wurdest dem Club hinzugefügt'
            }else if(response.status === 281){
              _this.addInfo = 'Einladung ungültig.'
            }else if(response.status === 282){
              _this.addInfo = 'Einladung abgelaufen'
            }else if(response.status === 283){
              _this.addInfo = 'Einladung bereits angenommen'
            }
          })
          .catch(e => {console.log(e)})
          .finally(() => {
            _this.getClubItems()
            _this.getUserItems()
            _this.getRoleItems()
          })
    },
    getClubItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('profil/getMyClubs', fD)
          .then((response) => {
            if(response.status === 250){
              _this.clubItems = response.data
            }
          })
          .catch(e => {console.log(e)})
    },
    getUserItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getUserItems', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.userItems = response.data
            }
          })
          .catch(e => {console.log(e)})
    },
    getRoleItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('usermanagement/getRoleItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.roleItems = response.data
            }
          })
          .catch(e => {console.log(e)})
    },
    invite(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId', this.config.organizerId)
      fD.append('roleId', this.roleId)
      fD.append('email', this.email)
      this.axios.post('usermanagement/invite', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.addUser = false
              _this.addInfo = '<span class="color-orange">'+this.email + '</span> wurde eingeladen.'
              _this.email = ''
            }
          })
          .catch(e => {console.log(e)})
    }
  }
}
</script>

<style>
  main#usermanagement{
    padding-bottom: 80px;
  }
  #usermanagement h1{
    font-size: 2em;
    margin-bottom: 16px;
  }
  #usermanagement h3{
    font-size: 1.4em;
  }
  #usermanagement .field input{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
  #userOverview table{
    width: 100%;
  }
  #userOverview thead td{
    padding: 8px;
    font-weight: bold;
  }
  #createModal .vueModalInner{
    width: calc(100vw - 16px);
    max-width: 500px;
  }
  #createModal h3{
    font-size: 1.6em;
  }
  #createModal .field label{
    display: block;
  }
  #createModal .field input,
  #addUserModal .field input{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
  #createModal .field select,
  #addUserModal .field select{
    display: block;
    width: calc(100%);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
</style>