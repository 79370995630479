<template>
  <tr>
    <td><img :src="'/assets/images/users/' + customerItems.avatar" class="width-32 circular"/> {{ customerItems.firstname }} {{ customerItems.lastname }}</td>
    <td>{{ customerItems.email }}</td>
  </tr>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "customerItems",
  props: ["customerItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style scoped>
  td{
    padding: 8px;
  }
  td img{
    vertical-align: middle;
  }
  tr{
    background-color: rgba(0,0,0,0.03);
    border-bottom: 4px solid #ffffff;
  }
  #editModal .vueModalInner{
    width: calc(100vw - 16px);
    max-width: 500px;
  }
  #editModal h3{
    font-size: 1.6em;
  }
  #editModal .field label{
    display: block;
  }
  #editModal .field input{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
  #editModal .field select{
    display: block;
    width: calc(100%);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
</style>