<template>
  <article class="ticket" @click="editTicketModal = true;">
    <div class="grid-12">
      <div class="col-sp-9 row-sp-12 ticketContent">
        <h4>{{ ticketItems.ticketTitle }}</h4>
        <h5>{{ ticketItems.ticketDescription }}</h5>
      </div>
      <div class="col-sp-3 row-sp-12 t-right ticketStats">
        <div>
          <span class="bgcolor-lightgrey button tiny">
            {{ ticketItems.quantity }}
          </span>
        </div>
        <div>
          <span class="bgcolor-orange color-white button tiny">
            {{ ticketItems.price }}€
          </span>
        </div>
      </div>
    </div>
  </article>
  <transition name="modal" id="editTicketModal" style="position: fixed;">
    <div class="vueModal" v-if="editTicketModal">
      <div class="vueModalOuter">
        <div class="vueModalInner">
          <h3 style="padding-left: 16px; font-size: 1.4em; margin-top: 16px;">Ticket erstellen</h3>
          <div class="grid-12">
            <div class="col-sp-12 col-sd-12 row-sp-12">
              <div class="pad-4">
                <div class="bgcolor-white pad-8 br-10 eventDataForm">
                  <div class="grid-12">
                    <div class="col-sp-12 row-sp-12 pad-4">
                      <div class="field">
                        <input type="text" placeholder="Ticketname (max. 20 Zeichen)" maxlength="20" required v-model="ticketTitle">
                      </div>
                    </div>
                    <div class="col-sp-12 row-sp-12 pad-4">
                      <div class="field">
                        <textarea type="text" rows="4" placeholder="Beschreibung & Informationen" maxlength="160" v-model="ticketDescription"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pad-4">
                <div class="bgcolor-white pad-8 br-10 eventDataForm">
                  <div class="grid-12">
                    <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                      <div class="field">
                        <label for="price">Brutto-Endpreis <span style="color: rgba(0,0,0,0.6); font-size: 0.8em;">(inkl. MwSt.)</span></label>
                        <input type="number" min="0.00" max="10000.00" step="0.01" id="price" required placeholder="Ticketpreis (€)" v-model="price">
                      </div>
                    </div>
                    <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                      <div class="field">
                        <label for="quantity">Verfügbare Tickets</label>
                        <input type="number" id="quantity" v-model="quantity" required placeholder="Anzahl an Verfügbaren Tickets">
                      </div>
                    </div>
                    <div class="col-sp-12 row-sp-12 pad-8">
                      <div class="field">
                        <input id="personal" type="checkbox" v-if="ticketItems.personal == 'true'" checked v-model="personal" >
                        <input id="personal" type="checkbox" v-else v-model="personal" >
                        <label for="personal" style="font-size: 0.9em">  Tickets sind personengebunden und können nicht weitergegeben werden</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-12">
            <div class="col-sp-12 col-tb-6 row-sp-12 pad-8">
              <button class="button bgcolor-second color-white" @click="editTicketModal = false">Abbrechen</button>
            </div>
            <div class="col-sp-12 col-tb-6 row-sp-12 pad-8 t-right">
              <button class="button bgcolor-orange color-white" @click="saveTicket(ticketItems.id)">Speichern</button>
            </div>
          </div>
          <div class="pad-8">
            <a style="font-size: 0.9em; color: rgba(0,0,0,0.6); cursor: pointer; text-decoration: underline" @click="deleteTicket(ticketItems.id)">Ticket löschen</a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "ticketItems",
  props: ["ticketItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      editTicketModal: false,
      ticketTitle: this.ticketItems.ticketTitle,
      ticketDescription: this.ticketItems.ticketDescription,
      price: this.ticketItems.price,
      quantity: this.ticketItems.quantity,
      personal: this.ticketItems.personal,
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    deleteTicket(ticketid){
      const _this = this
      const fD = new FormData()
      fD.append('ticketId', ticketid)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('ticketcenter/deleteTicket', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.editTicketModal = false
              this.$emit('update')
            }
          })
    },
    saveTicket(ticketid){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('ticketTitle', this.ticketTitle)
      fD.append('ticketDescription', this.ticketDescription)
      fD.append('price', this.price)
      fD.append('quantity', this.quantity)
      fD.append('ticketId', ticketid)
      fD.append('personal', this.personal)
      this.axios.post('ticketcenter/saveTicket', fD)
          .then((response) => {
            if(response.status === 250){
              _this.editTicketModal = false
              this.$emit('update')
            }
          })
    },
  }
}
</script>

<style scoped>
.ticket{
  margin-top: 8px;
  cursor: pointer;
  border: 2px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  padding: 8px;
  transition: 0.3s;
}
.ticket:hover{
  background-color: rgba(0,0,0,0.02);
}
.ticket .ticketContent{
  padding: 8px;
  padding-left: 16px;
}
.ticket .ticketContent h4{
  font-size: 1.1em;
  font-family: 'Helvetica';
  font-weight: 600;
  margin-top: 4px;
}
.ticket .ticketContent h5{
  font-size: 0.95em;
  font-family: 'Helvetica';
  font-weight: 300;
}
.ticket .ticketContent h6{
  font-size: 0.9em;
  font-family: 'Helvetica';
  font-weight: 300;
}
.ticket .ticketStats span{
  margin-bottom: 8px;
}
</style>