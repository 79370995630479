import { createRouter, createWebHistory } from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import profil from "@/views/profil";
import wallet from "@/views/wallet";
import login from "@/views/login";
import home from "@/views/home";
import dashboard from "@/views/dashboard";
import promotions from "@/views/promotions";
import customerinfo from "@/views/customerinfo";
import reports from "@/views/reports";
import clubmanager from "@/views/clubmanager";
import usermanagement from "@/views/usermanagement";
import ticketcenter from "@/views/ticketcenter";
import notifications from "@/views/notifications";
import events from "@/views/events";
import register from "@/views/register";
import publicNavBar from "@/components/navBar/publicNavBar";
import navBar from "@/components/navBar/navBar";
import footer from "@/components/footer/footer";
import eventOverview from "@/views/events/overview";
import eventCreate from "@/views/events/create";
import eventEdit from "@/views/events/edit";
import ticketOverview from "@/views/ticketcenter/overview";
import ticketCreate from "@/views/ticketcenter/create";
import ticketEdit from "@/views/ticketcenter/edit";
import eventlyPay from "@/views/eventlyPay";
import scanner from "@/views/ticketcenter/scanner";

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    components: {
      navBar: publicNavBar,
      default: home
    },
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      default: login
    },
    meta: { noAuth: true },
  },
  {
    path: '/register',
    name: 'Register',
    components: {
      default: register,
      footer: footer
    },
    meta: { noAuth: true },
  },
  {
    path: '/profil',
    name: 'Profil',
    components: {
      navBar: navBar,
      default: profil,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      navBar: navBar,
      default: dashboard,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/kundeninfo',
    name: 'Customerinfo',
    components: {
      navBar: navBar,
      default: customerinfo,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/umsatzberichte',
    name: 'Reports',
    components: {
      navBar: navBar,
      default: reports,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/clubmanager',
    name: 'Clubmanager',
    components: {
      navBar: navBar,
      default: clubmanager,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/promotions',
    name: 'Promotions',
    components: {
      navBar: navBar,
      default: promotions,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/benutzerverwaltung',
    alias: '/usermanagement',
    name: 'Usermanagement',
    components: {
      navBar: navBar,
      default: usermanagement,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    components: {
      navBar: navBar,
      default: notifications,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/ticketcenter/:page?',
    name: 'Ticketcenter',
    components: {
      navBar: navBar,
      default: ticketcenter,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'TicketOverview',
        component: ticketOverview
      },
      {
        path: 'create',
        name: 'TicketCreate',
        component: ticketCreate
      },
      {
        path: 'edit/:id',
        name: 'TicketEdit',
        component: ticketEdit
      },
      {
        path: 'scanner',
        name: 'scanner',
        component: scanner
      }
    ],
    meta: { auth: true },
  },
  {
    path: '/events/:page?',
    name: 'Events',
    components: {
      navBar: navBar,
      default: events,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'EventOverview',
        component: eventOverview
      },
      {
        path: 'create',
        name: 'EventCreate',
        component: eventCreate
      },
      {
        path: 'edit/:id',
        name: 'EventEdit',
        component: eventEdit,
        props: true
      }
    ],
    meta: { auth: true },
  },
  {
    path: '/wallet',
    name: 'Wallet',
    components: {
      navBar: navBar,
      default: wallet,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/eventlyPay',
    name: 'EventlyPay',
    components: {
      navBar: navBar,
      default: eventlyPay,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    name: '404',
    path: '/:pathMatch(.*)*',
    alias: '/404',
    redirect: () => {
      return { path: '/home' }
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()

  window.scrollTo(0, 0)
  window.onscroll = function () {}
  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(function (resolved) {
          config.loading = true
            next()
        })
        .catch(function (e) {
          next({
            path: '/login',
            query: { redirect: encodeURI(to.fullPath) }
          })
        })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .then(() => {
          next({
            name: 'Dashboard'
          })
        })
        .catch((e)=> {
          next()
        })
        .finally(function () {
          config.loading = true
        })
  } else {
    auth.update()
        .catch(e =>{
          //BEHEBUNG!
          console.log(e)
        })
    config.loading = true
    next()
  }
})

window.router = router

export default router
