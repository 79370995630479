<template>
  <div v-if="notificationItems.promotion == 1">
    <div class="promotionTag">
      <p>Gesponsert</p>
    </div>
    <article class="notification promotion grid-12">
      <div class="col-sp-2 col-tb-1 row-sp-12 imageBox">
        <img :src="'assets/images/icons/'+notificationItems.image" class="width-32">
      </div>
      <div class="col-sp-10  col-tb-11 row-sp-12 textBox">
        <p v-html="notificationItems.content" />
      </div>
    </article>
  </div>
  <div v-else>
    <article class="notification grid-12">
      <div class="col-sp-2 col-tb-1 row-sp-12 imageBox">
        <img :src="'assets/images/icons/'+notificationItems.image" class="width-32">
      </div>
      <div class="col-sp-10 col-tb-11 row-sp-12 textBox">
        <p v-html="notificationItems.content" />
      </div>
    </article>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "NotificationItems",
  props: ["notificationItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
  }
}
</script>

<style>
.notification{
  position: relative;
  margin-bottom: 32px;
  background-color: #ffffff;
  color: rgba(0,0,0,0.8);
  border-radius: 10px;
  padding: 16px;
  border: 2px solid #ffffff;
}
.notification.promotion{
  border: 2px solid #F2A43A;
}
.notification .imageBox{
  position: relative;
  vertical-align: middle;
}
.notification .imageBox img{
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  filter: invert(0.8);
  vertical-align: middle;
}
.notification .textBox{
  position: relative;
  padding: 16px 0;
}
.notification .textBox span{
  color: #F2A43A;
  font-weight: 800;
}
.promotionTag{
  font-size: 0.8em;
  color: rgba(255,255,255,0.6);
  margin-bottom: 8px;
}
</style>