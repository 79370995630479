<template>
  <main id="home" class="content-1400">
    <section id="title" class="pad-16 grid-12">
      <div class="col-sp-12 col-sd-6 row-sp-12">
        <h1>event<span class="color-orange">ly</span></h1>
        <h2>Die Software Lösung für</h2>
        <h3>Clubs & Veranstalter</h3>
      </div>
      <div class="col-sp-12 col-sd-6 row-sp-12">
        <img src="assets/images/website/3d-female-party.png" class="responsive-img"/>
      </div>
    </section>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Home",
  title: "Home",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style scoped>
main{
  padding-top: 80px;
}
#home #title{

}
#home #title h1{
  font-size: 4em;
  font-weight: 800;
  margin-top: 20vh;
  margin-left: 5vw;
}
#home #title h2{
  font-size: 3em;
  margin-left: 5vw;
}
#home #title h3{
  font-size: 3em;
  color: rgba(0,0,0,0.6);
  margin-top: -4px;
  font-weight: 200;
  margin-left: 5vw;
  margin-bottom: 32px;
}
</style>