<template>
  <main id="ticketEdit">
    <div class="content-1400">
      <div class="pad-8">
        <div class="grid-12">
          <div class="col-sp-12 row-sp-12 pad-4">
            <h1>Ticket Informationen</h1>
          </div>
          <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
            <div class="bgcolor-white br-10 pad-16" id="ticketResults">
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-16">
                  <h3>
                    <span class="f-normal">{{ ticketDetails.title }} - </span>
                    <span class="f-bold">{{ ticketDetails.ticketTitle }}</span>
                  </h3>
                </div>
              </div>
              <div class="grid-12">
                <div class="col-sp-12 col-sd-12 row-sp-12">
                  <div class="pad-4">
                    <div class="eventDataForm">
                      <div class="grid-12">
                        <div class="col-sp-12 row-sp-12 pad-4">
                          <div class="field">
                            <input type="text" placeholder="Ticketname (max. 20 Zeichen)" maxlength="20" required v-model="ticketTitle">
                          </div>
                        </div>
                        <div class="col-sp-12 row-sp-12 pad-4">
                          <div class="field">
                            <textarea type="text" rows="4" placeholder="Beschreibung & Informationen" maxlength="40" v-model="ticketDescription"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pad-4">
                    <div class="eventDataForm">
                      <div class="grid-12">
                        <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                          <div class="field">
                            <label for="price">Brutto-Endpreis <span style="color: rgba(0,0,0,0.6); font-size: 0.8em;">(inkl. MwSt.)</span></label>
                            <input type="number" min="0.00" max="10000.00" step="0.01" id="price" required placeholder="Ticketpreis (€)" v-model="price">
                          </div>
                        </div>
                        <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                          <div class="field">
                            <label for="quantity">Verfügbare Tickets</label>
                            <input type="number" id="quantity" v-model="quantity" required placeholder="Anzahl an Verfügbaren Tickets">
                          </div>
                        </div>
                        <div class="col-sp-12 row-sp-12 pad-8">
                          <div class="field">
                            <input id="personal" type="checkbox" v-model="personal">
                            <label for="personal" style="font-size: 0.9em">  Tickets sind personengebunden und können nicht weitergegeben werden</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="t-right">
                      <div class="pad-4"></div>
                      <button class="button bgcolor-orange color-white" @click="saveTicket()">Änderungen Speichern</button>
                      <div class="t-right pad-4" v-if="saveMessage == true">
                        Erfolgreich gespeichert
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12">
            <div class="grid-12">
              <div class="col-sp-12 col-tb-4 col-sd-12 row-sp-12 pad-4">
                <div class="bgcolor-white br-10 pad-16 statsBox">
                  <div class="grid-12">
                    <div class="col-sp-10 row-sp-6">
                      <h3>Tickets verkauft</h3>
                    </div>
                    <div class="col-sp-2 row-sp-12 t-right">
                      <img src="assets/images/icons/ticket-icon-orange.svg" class="width-48">
                    </div>
                    <div class="col-sp-10 row-sp-6">
                      <h4>{{ ticketCount }} von {{ ticketDetails.quantity }}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 col-tb-4 col-sd-12 row-sp-12 pad-4">
                <div class="bgcolor-white br-10 pad-16 statsBox">
                  <div class="grid-12">
                    <div class="col-sp-10 row-sp-6">
                      <h3>Ticket-Umsatz</h3>
                    </div>
                    <div class="col-sp-2 row-sp-12 t-right">
                      <img src="assets/images/icons/money-icon-orange.svg" class="width-48">
                    </div>
                    <div class="col-sp-10 row-sp-6">
                      <h4>{{ ticketTurnover.toFixed(2) }}€</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 col-tb-4 col-sd-12 row-sp-12 pad-4">
                <div class="bgcolor-white br-10 pad-16 statsBox">
                  <div class="grid-12">
                    <div class="col-sp-10 row-sp-6">
                      <h3>Genutzte Tickets</h3>
                    </div>
                    <div class="col-sp-2 row-sp-12 t-right">
                      <img src="assets/images/icons/used-ticket-icon-orange.svg" class="width-48">
                    </div>
                    <div class="col-sp-10 row-sp-6">
                      <h4>
                        {{ ticketScanCount }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
            <div class="bgcolor-white br-10 pad-32">
              <h3>
                Generierte Tickets
              </h3>
              <div class="pad-8"></div>
              <div>
                <table v-if="ticketItems != ''">
                  <thead>
                    <tr class="f-bold">
                      <td>TicketID</td>
                      <td>Person</td>
                      <td>Kaufpreis</td>
                      <td>SicherheitsCode</td>
                      <td>Scan-Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    <ticketItems v-for="titems in ticketItems" :ticketItems="titems" :key="titems.id"/>
                  </tbody>
                </table>
                <p v-else class="t-center pad-16" style="color: rgba(0,0,0,0.4)">
                  - Es wurden noch keine Tickets generiert / verkauft -
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import ticketItems from "@/components/ticketcenter/tableticket";


export default {
  name: "TicketEdit",
  title: 'Ticket Event',
  components: {
    ticketItems: ticketItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      ticketDetails: '',
      ticketItems: '',
      ticketCount: 0,
      ticketScanCount: 0,
      ticketTurnover: 0,
      ticketTitle: '',
      ticketDescription: '',
      price: 0,
      quantity: 0,
      personal: 0,
      saveMessage: false,
    }
  },
  mounted() {
    this.config.loading = false
    this.getTicketDetails()
    this.getSingleTickets()
  },
  methods: {
    getTicketDetails() {
      const _this = this
      const fD = new FormData()
      fD.append('id', this.$route.params.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('ticketcenter/getticketById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.ticketDetails = response.data
              _this.ticketTitle = response.data.ticketTitle
              _this.ticketDescription = response.data.ticketDescription
              _this.price = response.data.price
              _this.quantity = response.data.quantity
              _this.personal = response.data.personal

            }
          })
    },
    getSingleTickets(){
      const _this = this
      const fD = new FormData()
      fD.append('ParentId', this.$route.params.id)
      this.axios.post('ticketcenter/getSingleTickets', fD)
          .then((response) => {
            if(response.status === 250){
              _this.ticketCount = response.data.length
              _this.ticketItems = response.data
              response.data.forEach((item, index) => {
                _this.ticketTurnover = _this.ticketTurnover + Number(item.buyPrice)
                if(item.status == 1){
                  _this.ticketScanCount++;
                }
              })
            }
          })
    },
    saveTicket(){
      const _this = this
      const fD = new FormData()
      fD.append('ticketTitle', this.ticketTitle)
      fD.append('ticketDescription', this.ticketDescription)
      fD.append('price', this.price)
      fD.append('quantity', this.quantity)
      fD.append('ticketId', this.$route.params.id)
      fD.append('personal', this.personal)
      this.axios.post('ticketcenter/saveTicket', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.saveMessage = true
              _this.getTicketDetails()
            }
          })
    },
  }
}
</script>

<style scoped>
#ticketEdit h1{
  font-size: 2em;
}
#ticketEdit h3{
  font-size: 1.4em;
}
.statsBox h3{
  font-size: 1.6em;
}
.statsBox h4{
  font-size: 1.2em;
  margin-bottom: 0!important;
  color: rgba(0,0,0,0.6);
}
.statsBox .t-right h4{
  font-weight: 800;
}
#ticketEdit table{
  width: 100%;
}
#ticketEdit thead td{
  padding: 8px;
}
#ticketEdit tbody tr{
  border-bottom: 2px solid rgba(0,0,0,0.03);
}
</style>