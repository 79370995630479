<template>
  <main id="events">
    <router-view></router-view>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";


export default {
  name: "Events",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      page: 'overview',
      ticketSale: true,
      eventItems: [],
      cities: [],
      city: null,
      searchString: ''
    }
  },
  mounted() {
    this.config.loading = false
    //this.geteventItems()
  },
  methods: {
    publishEvent(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('image', null)
      fD.append('ticketSale', this.ticketSale)
      fD.append('ticketSaleLink', this.ticketSaleLink)
      fD.append('title', this.title)
      fD.append('label', this.label)
      fD.append('subTitle', this.subTitle)
      fD.append('description', this.description)
      fD.append('categories', this.categories)
      fD.append('eventlyPay', this.eventlyPay)
      fD.append('startTime', this.startTime)
      fD.append('endTime', this.endTime)
      fD.append('city', this.city)
      fD.append('location', this.location)
      fD.append('public', 1)
      this.axios.post('events/createEvent', fD)
          .then((response) => {
            if(response.status === 250){
              _this.$router.push('Events')
            }
          })
    },
    geteventItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('events/geteventItems', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.eventItems = response.data
            }
          })
    },
    loadCities(event){
      const _this = this
      this.searchString = event.input
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('search', event.input)
      this.axios.post('events/cities', fD)
          .then(r => {
            if(r.status === 250 && _this.searchString === event.input) _this.cities = r.data
          })
          .catch(e => {
            console.error(e)
          })
    },
    selectCity(item){
      this.city = item
    }
  }
}
</script>

<style>
main#events{
  padding-bottom: 80px;
}
</style>