<template>
  <header>
    <div class="grid-12">
      <div class="col-sp-3 row-sp-12 pad-8" style="padding-top: 10px!important;">
        <img v-if="menuModal == false" @click="menuModal = true" src="assets/images/icons/menu-icon.svg" class="width-32 menubtn"/>
        <img v-else @click="menuModal = false" src="assets/images/icons/menu-icon.svg" class="width-32 menubtn" style="margin-left: 270px;">
      </div>
      <div class="col-sp-9 row-sp-12 pad-8 t-right">
        <router-link to="/events/create" style="margin-right: 8px;" class="desktopView">
          <img src="assets/images/icons/plus-white.svg" class="width-20 pad-8 menubtn"/>
        </router-link>
        <router-link to="/notifications" style="margin-right: 8px;" @click="readNotifications()" id="notificationBell">
          <img src="assets/images/icons/bell-white.svg" style="width: 18px;" class="pad-8 menubtn"/>
          <span v-if="notificationCount > 0">{{ notificationCount }}</span>
        </router-link>
        <router-link to="/profil" class="desktopView">
          <img :src="config.projectURL + 'assets/images/users/'+user.avatar" class="image width-40 circular"/>
        </router-link>
      </div>
    </div>
  </header>
  <transition name="modal" id="sideMenu">
    <div class="vueModal" v-if="menuModal">
      <div class="vueModalOuter" @click="menuModal = false">
        <div class="menuContent">
          <div class="header">
            <!--<img src="assets/images/eventhoster/unlimeted-events.png" class="width-200">-->
            <h1>event<span class="color-orange">ly</span></h1>
            <div class="grid-12">
              <div class="col-sp-6 row-sp-12 pad-4">
                <router-link to="/eventlypay" class="button bgcolor-orange color-white t-center" style="display: block; padding: 12px 16px;">eventlyPay</router-link>
              </div>
              <div class="col-sp-6 row-sp-12 pad-4">
                <router-link to="/ticketcenter/scanner" @click="menuModal = false" class="button bgcolor-black color-white t-center" style="display: block;padding: 12px 16px;">Ticket-Scan</router-link>
              </div>
            </div>
          </div>
          <h5>Allgemein</h5>
          <ul class="navTop pad-4">
            <li>
              <router-link to="/dashboard" @click="menuModal = false">
                <img src="assets/images/icons/home-white.svg" class="width-28"/> Dashboard
              </router-link>
            </li>
            <li>
              <router-link to="/events" @click="menuModal = false">
                <img src="assets/images/icons/calendar-white.svg" class="width-28"/> Meine Events
              </router-link>
            </li>
            <li>
              <router-link to="/ticketcenter" @click="menuModal = false">
                <img src="assets/images/icons/ticket-white.svg" class="width-28"/> Ticketcenter
              </router-link>
            </li>
            <li>
              <router-link to="/kundeninfo" @click="menuModal = false">
                <img src="assets/images/icons/user-white.svg" class="width-28"/> Kundenübersicht
              </router-link>
            </li>
          </ul>
          <h5>Verwaltung</h5>
          <ul class="navTop pad-4">
            <li>
              <router-link to="/clubmanager" @click="menuModal = false">
                <img src="assets/images/icons/disco-white.svg" class="width-28"/> Meine Clubs
              </router-link>
            </li>
            <li>
              <router-link to="/benutzerverwaltung" @click="menuModal = false">
                <img src="assets/images/icons/user-white.svg" class="width-28"/> Benutzerverwaltung
              </router-link>
            </li>
            <li>
              <router-link to="/umsatzberichte" @click="menuModal = false">
                <img src="assets/images/icons/umsatz-white.svg" class="width-28"/> Umsatzberichte
              </router-link>
            </li>
            <li>
              <router-link to="/eventlypay" @click="menuModal = false">
                <img src="assets/images/icons/charge-white.svg" class="width-28"/> eventlyPay
              </router-link>
            </li>
            <li>
              <router-link to="/promotions" @click="menuModal = false">
                <img src="assets/images/icons/promotion-white.svg" class="width-28"/> Promotions
              </router-link>
            </li>
          </ul>
          <div class="footer">
            <div class="grid-12">
              <div class="col-sp-2 row-sp-12 pad-4">
                <img :src="config.projectURL + 'assets/images/users/'+user.avatar" class="image width-40 circular"/>
              </div>
              <div class="col-sp-9 row-sp-12 pad-4">
                <h3>{{user.firstname}} {{user.lastname}}</h3>
                <h4>{{user.email}}</h4>
              </div>
              <div class="col-sp-1 row-sp-12 t-right" style="padding-top: 16px;">
                <img style="cursor: pointer;" src="assets/images/icons/sign-out.svg" class="width-24" @click="signOutModal = true"/>
              </div>
            </div>
          </div>
      </div>
      </div>
    </div>
  </transition>
  <transition name="modal" id="signOutModal" style="position: fixed;">
    <div class="vueModal" v-if="signOutModal">
      <div class="vueModalOuter" @click="signOutModal = false">
        <div class="vueModalInner">
          <h3 class="pad-4 t-center f-bold">Möchtest du dich wirklich ausloggen?</h3>
          <div class="grid-12">
            <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
              <button class="button bgcolor-second color-white" @click="signOutModal = false">Abbrechen</button>
            </div>
            <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
              <button class="button bgcolor-orange color-white" @click="auth.logout();signOutModal = false">Abmelden</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "NavBar",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      menuModal: false,
      notificationCount: 0,
      signOutModal: false,
    }
  },
  mounted() {
    this.getNotificationsCount()
  },
  methods:{
    getNotificationsCount(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/getNotificationsCount', fD)
          .then((response) => {
            if(response.status === 250){
              _this.notificationCount = response.data.length
            }
          })
    },
    readNotifications(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/readNotifications', fD)
          .then((response) => {
            if(response.status === 250){
              _this.notificationCount = 0
            }
          })
    },
  }
}
</script>

<style scoped>
header{
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0px;
}
header img.image{
  border: 2px solid #ffffff;
}
header .menubtn{
  background-color: #000000;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
  filter: invert(1);
  border: 1px solid rgba(255,255,255,0.1);
}
header .menubtn img{
  filter: invert(1);
}
#sideMenu{
  position: fixed;
}
#sideMenu .menuContent{
  width: 100%;
  max-width: 300px;
  position: fixed;
  left: 0;
  background-color: #ffffff;
  height: 100vh;
  top: 0;
  padding: 16px;
  padding-top: 8px;
  z-index: 99;
}
#sideMenu .menuContent h1{
  font-size: 2em;
  padding-left: 8px;
  padding-bottom: 16px;
}
#sideMenu .menuContent h5{
  text-transform: uppercase;
  font-size: 0.8em;
  color: rgba(0,0,0,0.4);
  font-weight: 800;
  margin-top: 32px;
}
#sideMenu .menuContent .navTop{
  padding: 0;
  margin: 0;
  padding-top: 16px;
}
#sideMenu .menuContent .navTop li{
  list-style: none;
  display: block;
  margin-bottom: 8px;
}
#sideMenu .menuContent .navTop li a{
  text-decoration: none;
  display: block;
  color: var(--second-bg-color);
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.2em;
  transition: 0.3s;
}
#sideMenu .menuContent .navTop li a img{
  vertical-align: -6px;
  filter: invert(0.6)
}
#sideMenu .menuContent .navTop li a button{
  float: right;
  margin-top: -4px;
}
#sideMenu .menuContent .navTop li a:hover{
  color: #F2A43A;
}
#sideMenu .menuContent .footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 300px;
  padding: 16px;
  background-color: rgba(0,0,0,0.05);
}
#sideMenu .menuContent .footer h3{
  padding-top: 4px;
  font-weight: 700;
}
#sideMenu .menuContent .footer h4{
  font-weight: 300;
  color: rgba(0,0,0,0.6);
}
#notificationBell {
  position: relative;
}
#notificationBell span{
  position: absolute;
  background-color: #F2A43A;
  color: #ffffff;
  z-index: 3;
  left: 24px;
  top: 0px;
  border-radius: 90%;
  width: 12px;
  height: 12px;
  font-size: 0.7em;
  font-weight: bold;
  text-align: center;
  vertical-align: center;
  padding: 4px;

}
.desktopView{
  display: inline-block;
}
@media (max-width: 768px) {
  .desktopView{
    display: none;
  }
}

</style>