<template>
  <tr>
    <td>{{ticketItems.firstname}} {{ticketItems.lastname}}</td>
    <td>{{ticketItems.secureCode}}</td>
    <td>{{covertScanTimestamp}}</td>
  </tr>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "ticketItems",
  props: ["ticketItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      covertScanTimestamp: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.covertTime()
  },
  methods: {
    covertTime(){
      const _this = this
      let timestampArr = this.ticketItems.scanTimestamp.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      _this.covertScanTimestamp = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    },
  }
}
</script>

<style scoped>
tr td{
  padding: 4px;
}
</style>