<template>
  <tr>
    <td>#{{ ticketItems.id }}</td>
    <td>
      <router-link class="color-orange" :to="'/kundeninfo/'+ticketItems.userId">{{ ticketItems.firstname }} {{ ticketItems.lastname }}</router-link>
    </td>
    <td>{{ ticketItems.buyPrice }}€</td>
    <td>{{ ticketItems.secureCode }}</td>
    <td>
      <span class="label scan" v-if="ticketItems.scanTimestamp != null">
        Gescant ({{covertStartTime}})
      </span>
      <span class="label unscan" v-else>
        Noch nicht gescant
      </span>
    </td>
  </tr>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "ticketItems",
  props: ["ticketItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      covertStartTime: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.covertTime()
  },
  methods: {
    covertTime(){
      if(this.ticketItems.scanTimestamp != null){
        const _this = this
        let timestampArr = this.ticketItems.scanTimestamp.split(" ");
        let date = timestampArr[0];
        let time = timestampArr[1];
        const dateArr = date.split("-");
        const timeArr = time.split(":");
        _this.covertStartTime = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
      }
    },
  }
}
</script>

<style scoped>
tbody td{
  padding: 16px 8px;
  font-size: 0.9em;
}
tbody td .label{
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 0.9em;
}
tbody td .label.scan{
  background-color: #9bf2d233;
  color: #67cba6;
}
tbody td .label.unscan{
  background-color: #ffd26a33;
  color: #f5b218;
}
</style>