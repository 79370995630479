<template>
  <main id="clubmanager">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-orange">Übersicht</h2>
        <h1 style="padding-left: 4px;">Clubmanager</h1>
        <div class="grid-12">
          <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <h3 style="padding-top: 2px;">Club Details</h3>
              <div class="pad-8"></div>
              <div class="grid-12">
                <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
                  <img :src="logo" class="responsive-img" v-if="this.logo && !this.logoNew">
                  <cropper-upload @cropped="c => {this.logoNew = c;}" :ratio="1.875" ref="cropper">
                    <button class="button bgcolor-lightgrey color-black fluid">Logo {{ organizerId > 0 ? 'ändern' : 'hochladen' }}</button>
                  </cropper-upload>
                </div>
                <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
                  <div class="field">
                    <label>Clubname</label>
                    <input type="text" placeholder="Clubname" v-model="organizerName" @keyup="saveMessage = false">
                  </div>
                  <div class="field">
                    <label>Beschreibung</label>
                    <textarea rows="3" placeholder="Beschreibung" v-model="aboutText" @keyup="saveMessage = false"></textarea>
                  </div>
                  <div class="field">
                    <label>Kategorien</label>
                    <select id="categories" multiple v-model="labels" @change="saveMessage = false">
                      <option :value="citems.id" v-for="citems in categoryOptionItems" :categoryOptionItems="citems" :key="citems.id" >{{ citems.emoji }} {{ citems.title }}</option>
                    </select>
                  </div>
                  <div class="t-right">
                    <div class="pad-4"></div>
                    <button @click="saveClubDetails" class="button bgcolor-orange color-white" :class="{disabled: organizerName.length < 1 || saveMessage}" :disabled="organizerName.length < 1 || saveMessage">
                      {{ saveMessage ? 'Gespeichert' : (organizerId > 0 ? 'Änderungen Speichern' : 'Club erstellen') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <div class="grid-12">
                <div class="col-sp-12 row-sp-12">
                  <h3 style="padding-top: 2px;" class="t-center">Club auswählen</h3>
                </div>
              </div>
              <clubItems v-for="citems in config.organizer" :clubItems="citems" :key="citems.id" @update="getClubDetails"/>
            </div>
            <div class="pad-4"></div>
            <div class="bgcolor-orange color-white pad-16 br-10" style="cursor: pointer;" @click="createClub">
              <div class="t-center f-bold">
                <h5>Neuen Club erstellen</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!--<div class="vueModal" id="createClubModal" v-if="createClubModal" style="position:fixed;">
    <div class="vueModalOuter" @click="createClubModal = false"/>
    <div class="vueModalInner">
      <div class="pad-16" />
      <h3 class="t-center">Club erstellen</h3>
      <div class="pad-8"></div>
      <div class="grid-12">
        <div class="col-sp-12 row-sp-12 pad-4">
          <cropper-upload @cropped="c => {this.titleImage = c; this.titleImageTemplate = false}" :ratio="1.875" ref="cropper">
            <button class="button bgcolor-lightgrey color-black fluid">Logo hochladen</button>
          </cropper-upload>
        </div>
        <div class="col-sp-12 row-sp-12 pad-4">
          <div class="field">
            <label>Clubname</label>
            <input type="text" placeholder="Clubname">
          </div>
          <div class="field">
            <label>Beschreibung</label>
            <textarea rows="3" placeholder="Beschreibung"></textarea>
          </div>
          <div class="field">
            <label>Kategorien</label>
            <select id="categories" multiple>
              <option :value="citems.id" v-for="citems in categoryOptionItems" :categoryOptionItems="citems" :key="citems.id" >{{ citems.emoji }} {{ citems.title }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="pad-16 t-center">
        <button @click="createClubModal = false" class="button bgcolor-second color-white" style="margin: 0 8px 8px 8px;">
          Abbrechen
        </button>
        <button type="button" @click="createClub" class="button bgcolor-orange color-white">
          Club erstellen
        </button>
      </div>
    </div>
  </div>-->
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import clubItems from "@/components/profil/club";
import CropperUpload from "@/components/images/cropperUpload";

export default {
  name: "Clubmanager",
  title: "Clubmanager",
  components: {
    CropperUpload,
    clubItems: clubItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      clubItems: [],
      //createClubModal: false,
      categoryOptionItems: [],
      organizerName: '',
      aboutText: '',
      labels: [],
      logo: '',
      logoNew: false,
      organizerId: 0,
      saveMessage: false,
    }
  },
  mounted() {
    this.config.loading = false
    this.getClubDetails()
    this.getCategoryOptionItems()
  },
  methods: {
    getClubDetails(){
      this.$refs.cropper.reset()
      this.saveMessage = false
      this.logoNew = false
      this.organizerId = this.config.getCurrentOrganizer.id
      this.organizerName = this.config.getCurrentOrganizer.organizerName
      this.aboutText = this.config.getCurrentOrganizer.aboutText
      this.labels = this.config.getCurrentOrganizer.labels.split(',')
      this.logo = this.config.projectURL + 'assets/images/eventhoster/'+this.config.getCurrentOrganizer.logo
    },
    createClub(){
      this.$refs.cropper.reset()
      this.saveMessage = false
      this.logoNew = false
      this.organizerId = -1
      this.organizerName = ''
      this.aboutText = ''
      this.labels = []
      this.logo = false
    },
    saveClubDetails(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.organizerId)
      fD.append('organizerName', this.organizerName)
      fD.append('aboutText', this.aboutText)
      fD.append('labels', this.labels)
      fD.append('logo', this.logoNew)
      this.axios.post('clubmanager/saveClubDetails', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then((r) => {
            console.log(r.status, r.data)
            if(r.status === 250){
              _this.config.organizer = r.data.organizer
              _this.config.organizerId = r.data.organizerId
              _this.getClubDetails()
              _this.saveMessage = true
            }
          })
    },
    getCategoryOptionItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('clubmanager/getCategoryOptionItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.categoryOptionItems = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getEventItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('dashboard/geteventItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.eventItems = response.data
            }
          })
    }
  }
}
</script>

<style>
main#clubmanager{
  padding-bottom: 80px;
}
  #clubmanager h1{
    font-size: 2em;
  }
  #createClubModal h3,
  #clubmanager h3{
    font-size: 1.2em;
  }
  #createClubModal .field input,
  #createClubModal .field textarea,
  #clubmanager .field input,
  #clubmanager .field textarea{
    display: block;
    width: calc(100% - 24px);
    max-width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
  #createClubModal .field select,
  #clubmanager .field select{
    display: block;
    width: 100%;
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
  .disabled{
    background-color: grey !important;
  }
</style>