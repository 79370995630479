<template>
  <article class="ticket" @click="$router.push({name: 'TicketEdit', params: { id: ticketItems.id }});">
    <div class="grid-12">
      <div class="col-sp-12 col-tb-2 row-sp-12">
        <img class="ticketImage" :src="config.projectURL + 'assets/images/events/'+ticketItems.eventId+'/title.jpg'"/>
      </div>
      <div class="col-sp-9 col-tb-7 row-sp-12 ticketContent">
        <h6>{{ this.covertStartTime }}</h6>
        <h4><span class="f-normal">{{ ticketItems.title }} -</span> {{ ticketItems.ticketTitle }}
          <span class="button bgcolor-orange color-white tiny" style="padding: 4px;">{{ ticketItems.price }}€</span>
        </h4>
        <h5>{{ ticketItems.ticketDescription }}</h5>
      </div>
      <div class="col-sp-3 row-sp-12 t-right ticketStats">
        <div>
          <span class="bgcolor-lightgrey button tiny">
            {{ ticketCount }} / {{ ticketItems.quantity }}
          </span>
        </div>
        <div>
          <span class="bgcolor-orange color-white button tiny">
            {{ ticketTurnover.toFixed(2) }}€
          </span>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "ticketItems",
  props: ["ticketItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      covertStartTime: '',
      ticketCount: 0,
      ticketTurnover: 0,
    }
  },
  mounted() {
    this.config.loading = false
    this.covertTime()
    this.getSingleTickets(this.ticketItems.id)
  },
  methods: {
    covertTime(){
      const _this = this
      let timestampArr = this.ticketItems.startTime.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      _this.covertStartTime = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    },
    getSingleTickets(ParentId){
      const _this = this
      const fD = new FormData()
      fD.append('ParentId', ParentId)
      this.axios.post('ticketcenter/getSingleTickets', fD)
          .then((response) => {
            if(response.status === 250){
              _this.ticketCount = response.data.length
              response.data.forEach((item, index) => {
                _this.ticketTurnover = _this.ticketTurnover + Number(item.buyPrice)
              })
            }
          })
    },
  }
}
</script>

<style scoped>
.ticket{
  margin-top: 16px;
  cursor: pointer;
  border: 2px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  padding: 16px;
  transition: 0.3s;
}
.ticket:hover{
  background-color: rgba(0,0,0,0.02);
}
.ticket .ticketContent{
  padding: 8px 8px 8px 16px;
}
.ticket .ticketImage{
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background-color: rgba(0,0,0,0.06);
  min-height: 80px;
}
.ticket .ticketContent h4{
  font-size: 1.1em;
  font-family: 'Helvetica';
  font-weight: 600;
  margin-top: 4px;
}
.ticket .ticketContent h5{
  font-size: 0.95em;
  font-family: 'Helvetica';
  font-weight: 300;
}
.ticket .ticketContent h6{
  font-size: 0.9em;
  font-family: 'Helvetica';
  font-weight: 300;
}
.ticket .ticketStats span{
  margin-bottom: 8px;
}
</style>