<template>
  <div id="createEvent" class="content-1400">
    <div class="pad-16">
      <h1 style="padding-left: 4px;display: inline;">Neues Event erstellen</h1>
      <div class="pad-4"></div>
      <div class="grid-12">
        <div class="col-sp-12 col-sd-4 row-sp-12">
          <div class="pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <section id="uploadBox" style="position: relative; z-index: 2;">
                <h3>Titelbild auswählen</h3>
                <h5>PNG oder JPEG (1200 x 640px)</h5>
                <cropper-upload @cropped="c => {this.titleImage = c; this.titleImageTemplate = false}" :ratio="1.875" ref="cropper">
                  <button class="button bgcolor-orange color-white tiny">Eigene Datei hochladen</button>
                </cropper-upload>
                <div class="divider">oder</div>
                <button @click="imageTemplatesModal = 1" class="button bgcolor-black color-white tiny">Aus Vorlagen auswählen</button>
              </section>
              <img class="responsive-img br-10" style="position: relative; z-index: 1; margin-top: -100%;" :src="config.projectURL + 'assets/images/events/templates/' + titleImageTemplate" v-if="titleImageTemplate">
              <img class="responsive-img br-10" style="position: relative; z-index: 1; margin-top: -100%;" :src="config.projectURL + 'assets/images/events/' + event.id + '/title.jpg'" v-else-if="!titleImage">
            </div>
          </div>
          <div class="pad-4">
            <div class="bgcolor-white pad-16 br-10">
              <h3 style="padding-left: 4px">Ticketmanager</h3>
              <div class="grid-12">
                <div class="col-sp-6 row-sp-12">
                  <h4 style="padding-left: 4px; padding-top: 4px;">Sind Tickets erforderlich?</h4>
                </div>
                <div class="col-sp-3 row-sp-12" @click="ticketSale = 1;">
                  <input id="radio1" type="radio" value="1" name="ticketSale" checked v-model="ticketSale">
                  <label for="radio1" style="padding-left: 4px;">Ja</label>
                </div>
                <div class="col-sp-3 row-sp-12" @click="ticketSale = 0; ticketImage = null">
                  <input id="radio2" type="radio" value="0" name="ticketSale" v-model="ticketSale">
                  <label for="radio2" style="padding-left: 4px;">Nein</label>
                </div>
              </div>
              <div v-if="ticketSale == true">
                <ticketItems v-for="titems in ticketItems" :ticketItems="titems" :key="titems.id" @update="getTicketItems()"/>
              </div>
              <section id="ticketBox" v-if="ticketSale == true">
                <div v-if="ticketItems == '' && (ticketSaleLink == null || ticketSaleLink == '')">
                  <h3>Tickets anlegen</h3>
                  <h5>Verkaufe direkt über unsere Plattform Tickets für dein Event oder hinterlege deinen aktuellen Ticketshop.</h5>
                  <button class="button bgcolor-orange color-white tiny" @click="createTicketModal = true;">Tickets erstellen</button>
                  <div class="divider">oder</div>
                  <button class="button bgcolor-black color-white tiny" @click="linkedTicketshopModal = true;">Ticketshop verlinken</button>
                </div>
                <div class="t-center" v-else-if="ticketItems != ''">
                  <button class="button bgcolor-orange color-white tiny" @click="createTicketModal = true;">Weiteres Ticket erstellen</button>
                </div>
                <div class="t-center" v-else>
                  <button class="button bgcolor-black color-white tiny" @click="linkedTicketshopModal = true;">Link zum Ticketshop anpassen</button>
                </div>
              </section>
            </div>
          </div>
          <div class="pad-4">
            <div class="bgcolor-orange pad-16 br-10">
              <h3 style="padding-left: 4px" class="color-white">Promotion - Erreiche mehr Menschen</h3>
              <h4 style="padding-left: 4px; padding-top: 4px; color: rgba(255,255,255,0.7)">Möchtest du dein Event bewerben?</h4>
              <div class="t-right">
                <button class="button bgcolor-second color-white">Promotion erstellen</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sp-12 col-sd-8 row-sp-12">
          <div class="pad-4">
            <div class="bgcolor-white pad-16 br-10 eventDataForm">
              <h3 style="padding-top: 2px; padding-left: 4px">Eventdaten</h3>
              <h4 style="padding-left: 4px;">Allgemein</h4>
              <div class="grid-12">
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <input type="text" placeholder="Titel (max. 20 Zeichen)" maxlength="20" v-model="title">
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <input type="text" placeholder="Subtitel (max. 40 Zeichen)" maxlength="40" v-model="subTitle">
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <textarea type="text" rows="12" placeholder="Beschreibung & Informationen" maxlength="400" v-model="description"></textarea>
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <label for="categories">Kategorie</label>
                    <select id="label" name="label" v-model="label">
                      <option value="Club">Club</option>
                      <option value="Rave">Rave</option>
                      <option value="Konzert">Konzert</option>
                      <option value="Straßenfest">Straßenfest</option>
                      <option value="Festival">Festival</option>
                    </select>
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <label for="categories">Eigenschaften</label>
                    <select id="categories" multiple v-model="categories">
                      <option :value="citems.id" v-for="citems in categoryOptionItems" :categoryOptionItems="citems" :key="citems.id" >{{ citems.emoji }} {{ citems.title }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <input id="eventlyPay" type="checkbox" v-model="eventlyPay">
                    <label for="eventlyPay" style="font-size: 0.9em"> Zahlungen mit <a href="#" class="color-orange">eventlyPay</a>  ist auf dem Event möglich</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pad-4">
            <div class="bgcolor-white pad-16 br-10 eventDataForm">
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label for="startTime">Start</label>
                    <input type="datetime-local" id="startTime" v-model="startTime">
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label for="endTime">Ende</label>
                    <input type="datetime-local" id="endTime" v-model="endTime">
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <SimpleTypeahead
                        id="city_search"
                        placeholder="Suche nach Orten..."
                        :items="cities"
                        :min-input-length="1"
                        :item-projection="item => {return item.name}"
                        @selectItem="item => {this.city = item}"
                        @onInput="loadCities"
                        :defaultItem="{id: event.city, name: event.cityName}"
                    />
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <div class="field">
                    <input type="text" placeholder="Adresse" v-model="location"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="t-right">
              <div class="pad-4"></div>
              <button v-if="this.public == 0" @click="this.publishEvent(0)" class="button bgcolor-second color-white" style="margin-right: 8px;">Entwurf speichern</button>
              <button v-if="this.public == 0" @click="this.publishEvent(1)" class="button bgcolor-orange color-white">Speichern + Veröffentlichen</button>
              <button v-if="this.public == 1" @click="this.publishEvent(1)" class="button bgcolor-orange color-white">Speichern</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <VueModal :show="createTicketModal" cancel="Abbrechen" approve="Ticket Erstellen" @cancel="createTicketModal = false" @close="createTicketModal = false" @approve="createTicket">
    <h3 style="padding-left: 16px; font-size: 1.4em; margin-top: 16px;">Ticket erstellen</h3>
    <div class="grid-12">
      <div class="col-sp-12 col-sd-12 row-sp-12">
        <div class="pad-4">
          <div class="bgcolor-white pad-8 br-10 eventDataForm">
            <div class="grid-12">
              <div class="col-sp-12 row-sp-12 pad-4">
                <div class="field">
                  <input type="text" placeholder="Ticketname (max. 20 Zeichen)" maxlength="20" required v-model="ticketTitle">
                </div>
              </div>
              <div class="col-sp-12 row-sp-12 pad-4">
                <div class="field">
                  <textarea type="text" rows="4" placeholder="Beschreibung & Informationen" maxlength="40" v-model="ticketDescription"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pad-4">
          <div class="bgcolor-white pad-8 br-10 eventDataForm">
            <div class="grid-12">
              <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                <div class="field">
                  <label for="price">Brutto-Endpreis <span style="color: rgba(0,0,0,0.6); font-size: 0.8em;">(inkl. MwSt.)</span></label>
                  <input type="number" min="0.00" max="10000.00" step="0.01" id="price" required placeholder="Ticketpreis (€)" v-model="price">
                </div>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                <div class="field">
                  <label for="quantity">Verfügbare Tickets</label>
                  <input type="number" id="quantity" v-model="quantity" required placeholder="Anzahl an Verfügbaren Tickets">
                </div>
              </div>
              <div class="col-sp-12 row-sp-12 pad-8">
                <div class="field">
                  <input id="personal" type="checkbox" v-model="personal">
                  <label for="personal" style="font-size: 0.9em">  Tickets sind personengebunden und können nicht weitergegeben werden</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </VueModal>
  <VueModal :show="linkedTicketshopModal" cancel="löschen" approve="Okay" @cancel="ticketSaleLink = null; linkedTicketshopModal = false" @approve="linkedTicketshopModal = false">
    <h3 style="padding-left: 16px; font-size: 1.4em; margin-top: 16px;">Ticketshop verlinken</h3>
    <div class="grid-12">
      <div class="col-sp-12 col-sd-12 row-sp-12">
        <div class="pad-4">
          <div class="bgcolor-white pad-8 br-10 eventDataForm">
            <div class="grid-12">
              <div class="col-sp-12 row-sp-12 pad-4">
                <div class="field">
                  <label for="ticketSaleLink">Link zum Ticketshop</label>
                  <input id="ticketSaleLink" type="text" placeholder="https://www.deinticketshop.de...." v-model="ticketSaleLink">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </VueModal>
  <VueModal :show="imageTemplatesModal" cancel="abbrechen" @close="imageTemplatesModal = false" @cancel="imageTemplatesModal = false">
    <h3 class="t-center" style="font-size: 1.4em; margin-top: 16px;">Titelbild aus Galerie auswählen</h3>
    <div class="grid-12">
      <div class="col-sp-12 col-sd-12 row-sp-12">
        <div class="pad-4">
          <div class="bgcolor-white pad-8 br-10">
            <div class="grid-12">
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-01.jpg')">
                <img src="assets/images/events/templates/template-01.jpg" class="br-10 responsive-img"/>
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-02.jpg')">
                <img src="assets/images/events/templates/template-02.jpg" class="br-10 responsive-img"/>
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-03.jpg')">
                <img src="assets/images/events/templates/template-03.jpg" class="br-10 responsive-img"/>
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-04.jpg')">
                <img src="assets/images/events/templates/template-04.jpg" class="br-10 responsive-img"/>
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-05.jpg')">
                <img src="assets/images/events/templates/template-05.jpg" class="br-10 responsive-img"/>
              </div>
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" @click="eventImageTemplate('template-06.jpg')">
                <img src="assets/images/events/templates/template-06.jpg" class="br-10 responsive-img"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </VueModal>
</template>

<script>
import SimpleTypeahead from "vue3-simple-typeahead";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import CropperUpload from "@/components/images/cropperUpload";
import ticketItems from "@/components/events/ticket";
import axios from "axios";
import {useRouter} from 'vue-router';

export default {
  name: "eventEdit",
  title: 'Edit Event',
  props: ['id'],
  components: {
    CropperUpload,
    SimpleTypeahead,
    ticketItems: ticketItems,
  },
  async setup(props) {
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const Router = useRouter();
    const fD = new FormData()
    fD.append('sessionID', auth.sessionID)
    fD.append('eventId', props.id)
    let event = {}
    let citiesTemp = []
    await axios.post('events/getEventByIdAndUser', fD)
        .then(async r => {
          if (r.status === 250) {
            event = r.data

            const fD = new FormData()
            fD.append('sessionID', auth.sessionID)
            fD.append('search', event.cityName)
            await axios.post('events/cities', fD)
                .then(r => {
                  console.log(r)
                  if (r.status === 250) citiesTemp = r.data
                })
                .catch(e => {
                  console.error(e)
                })
          } else {
            Router.push('/events')
          }
        })
        .catch(e => {
          console.error(e)
        })

    return {auth, config, user, event, citiesTemp}
  },
  data(){
    return {
      ticketSale: !!parseInt(this.event.ticketSale),
      cities: this.citiesTemp,
      city: {
        id: this.event.city,
        name: this.event.cityName
      },
      searchString: '',
      title: this.event.title,
      subTitle: this.event.subTitle,
      description: this.event.description,
      label: this.event.label,
      categories: this.event.categories.split(','),
      ticketSaleLink: this.event.ticketSaleLink == 'null' ? null : this.event.ticketSaleLink,
      eventlyPay: !!parseInt(this.event.eventlyPay),
      startTime: this.event.startTime,
      endTime: this.event.endTime,
      titleImage: null,
      titleImageTemplate: null,
      createTicketModal: false,
      linkedTicketshopModal: false,
      draftId: Math.random().toString(36).substr(2),
      ticketTitle: '',
      ticketDescription: '',
      price: '',
      quantity: '',
      personal: '',
      ticketItems: '',
      public: this.event.public,
      categoryOptionItems: [],
      imageTemplatesModal: false,
      location: this.event.location,
    }
  },
  mounted() {
    this.config.loading = false
    this.getCategoryOptionItems()
    this.getTicketItems()
  },
  methods: {
    publishEvent(publicState){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId', this.event.id)
      fD.append('ticketSale', this.ticketSale ? 1 : 0)
      fD.append('ticketSaleLink', this.ticketSaleLink)
      fD.append('title', this.title)
      fD.append('label', this.label)
      fD.append('subTitle', this.subTitle)
      fD.append('description', this.description)
      fD.append('categories', this.categories)
      fD.append('eventlyPay', this.eventlyPay)
      fD.append('startTime', this.startTime)
      fD.append('endTime', this.endTime)
      fD.append('city', this.city.id)
      fD.append('location', this.location)
      fD.append('public', publicState)
      fD.append('draftId', this.draftId)
      if(this.titleImageTemplate) {
        fD.append('titleImageTemplate', this.titleImageTemplate)
      }
      else if(this.titleImage) {
        fD.append('titleImage', this.titleImage, 'titleImage')
      }
      this.axios.post('events/editEvent', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.$router.push('/events')
            }
          })
          .catch(e => {console.error(e)})
    },
    loadCities(event){
      const _this = this
      this.searchString = event.input
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('search', event.input)
      this.axios.post('events/cities', fD)
          .then(r => {
            if(r.status === 250 && _this.searchString === event.input) _this.cities = r.data
          })
          .catch(e => {console.error(e)})
    },
    createTicket(){
      const _this = this
      const fD = new FormData()
      fD.append('ticketTitle', this.ticketTitle)
      fD.append('ticketDescription', this.ticketDescription)
      fD.append('price', this.price)
      fD.append('quantity', this.quantity)
      fD.append('eventId', this.event.id)
      fD.append('personal', this.personal)
      this.axios.post('ticketcenter/createTicket', fD)
          .then((response) => {
            if(response.status === 250){
              _this.ticketTitle = null;
              _this.ticketDescription = null;
              _this.price = null;
              _this.quantity = null;
              _this.personal = null;
              _this.createTicketModal = false
              _this.getTicketItems()
            }
          })
          .catch(e => {console.error(e)})
    },
    getTicketItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId', this.event.id)
      this.axios.post('ticketcenter/getTicketItems', fD)
          .then((response) => {
            console.log(response, 'test')
            if(response.status === 250){
              _this.ticketItems = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getCategoryOptionItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('events/getCategoryOptionItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.categoryOptionItems = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    eventImageTemplate(template){
      this.titleImageTemplate = template
      this.titleImage = false
      this.imageTemplatesModal = false
      this.$refs.cropper.reset()
    }
  }
}
</script>

<style>
#createEvent h1{
  font-size: 2em;
}
#createEvent h3{
  font-size: 1.2em;
}

#createEvent h4{
  font-size: 0.9em;
  color: rgba(0,0,0,0.6);
  margin-top: -4px;
  margin-bottom: 8px;
}
#uploadBox{
  border-radius: 10px;
  border: 2px dashed rgba(0,0,0,0.1);
  text-align: center;
  padding: 36px 32px;
}
#uploadBox h5{
  font-size: 0.9em;
  color: rgba(0,0,0,0.4);
  margin-bottom: 16px;
}
.eventDataForm .field input,
.eventDataForm .field textarea,
.eventDataForm .field select{
  display: block;
  padding: 12px;
  width: 100%;
  max-width: calc(100% - 20px);
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 10px;
}
.eventDataForm .field select{
  max-width: 100%;
}
.eventDataForm .field input[type=checkbox]{
  width: auto;
  display: inline;
  margin-right: 2px;
}
#ticketBox{
  border-radius: 10px;
  border: 2px dashed rgba(0,0,0,0.1);
  text-align: center;
  padding: 52px 32px;
  margin-top: 16px;
}
#ticketBox h5{
  font-size: 0.9em;
  color: rgba(0,0,0,0.4);
  margin-bottom: 16px;
}
#imageTemplatesModal img{
  transition: 0.3s;
  cursor: pointer;
}
#imageTemplatesModal img:hover{
  transform: scale(1.05);
}
</style>