<template>
  <main>
    <section id="walletHeader">
      <div class="pad-16">
        <div class="grid-12">
          <div class="col-sp-8 row-sp-12">
            <h1>Dein Guthaben</h1>
          </div>
          <div class="col-sp-4 row-sp-12 t-right">
            <button class="button bgcolor-orange color-white">{{creditAmount}}€</button>
          </div>
        </div>
      </div>
    </section>
    <section id="walletCTA">
      <div class="pad-8">
        <div class="grid-12">
          <div class="col-sp-6 row-sp-12 pad-8">
            <button @click="generatePayQRCode()" class="button bgcolor-orange color-white" id="pay">
              <img src="assets/images/icons/pay-white.svg" class="width-80"/>
              <h4>Bezahlen</h4>
            </button>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <button @click="chargeModal = true;" class="button bgcolor-second color-white" id="charge">
              <img src="assets/images/icons/charge-white.svg" class="width-80"/>
              <h4>Guthaben aufladen</h4>
            </button>
          </div>
        </div>
      </div>
    </section>

    <transition name="modal" id="paymentModal">
      <div class="vueModal" v-if="paymentModal" >
        <div class="vueModalOuter" @click="paymentModal = false;"/>
        <div class="vueModalInner bottom bgcolor-second">
          <div class="t-center pad-8">
            <h2>North<span class="f-bold">Pay</span></h2>
            <h3 class="color-white">Zeige diesen Code zum bezahlen vor</h3>
            <div class="t-center">
              <img id="qrious">
            </div>
            <div>Aktuelles Guthaben: {{creditAmount}}€</div>
            <div class="pad-8"></div>
            <button @click="paymentModal = false;" class="button bgcolor-black color-white fluid">Abbrechen</button>
          </div>
        </div>
      </div>
    </transition>
    <transition name="modal" id="chargeModal">
      <div class="vueModal" v-if="chargeModal" >
        <div class="vueModalOuter" @click="chargeModal = false;"/>
        <div class="vueModalInner bottom bgcolor-second">
          <div class="t-center pad-8">
            <h2>Guthaben aufladen</h2>
            <h3>Betrag auswählen</h3>
            <div class="grid-12 t-center selectionButtons">
              <div class="col-sp-4 row-sp-12 pad-4">
                <button @click="this.chargeAmount = 10.00" class="button bgcolor-black color-white">
                  10,00€
                </button>
              </div>
              <div class="col-sp-4 row-sp-12 pad-4">
                <button @click="this.chargeAmount = 25.00" class="button bgcolor-black color-white">
                  25,00€
                </button>
              </div>
              <div class="col-sp-4 row-sp-12 pad-4">
                <button @click="this.chargeAmount = 50.00" class="button bgcolor-black color-white">
                  50,00€
                </button>
              </div>
            </div>
            <div>

            </div>
            <button class="button bgcolor-orange color-white fluid">Weiter</button>
            <div class="pad-4"></div>
            <button @click="chargeModal = false;" class="button bgcolor-black color-white fluid">Abbrechen</button>
          </div>
        </div>
      </div>
    </transition>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Wallet",
  title: "Wallet",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      paymentModal: false,
      chargeModal: false,
      creditAmount: '25,89',
      chargeAmount: 5.00,
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    generatePayQRCode() {
      this.paymentModal = true;
      this.$nextTick(() => {
        var qr = window.qr = new QRious({
          element: document.getElementById('qrious'),
          size: 250,
          value: '8czw98tuz493t02ictjn3ivtc39',
          background: 'transparent',
          foreground: '#F2A43A',
          level: 'H',
        });
      });
    }
  }
}
</script>

<style scoped>
#walletHeader{
  padding-top: 16px;
}
#walletHeader h1{
  font-size: 2em;
  font-weight: 300;
}
#walletHeader button{
  font-size: 1em;
  padding: 8px;
}
#walletCTA button{
  width: 100%;
}
#paymentModal{
  position: fixed;
}
#paymentModal .vueModalInner{
  min-width: 80vw;
}
#paymentModal h2{
  font-size: 2em;
}
#paymentModal h3{
  font-size: 1.2em;
  margin-bottom: 16px;
}
#chargeModal{
  position: fixed;
}
#chargeModal .vueModalInner{
  min-width: 80vw;
}
#chargeModal h2{
  font-size: 1.8em;
  font-weight: 300;
}
#chargeModal h3{
  font-size: 1.2em;
  margin-bottom: 16px;
}
#chargeModal .selectionButtons button{
  padding: 12px;
  font-size: 1.2em;
}
</style>