<template>
  <router-view name="navBar" />
  <router-view v-slot="{Component, route}">
    <transition name="router">
      <suspense>
        <template #default>
          <component :is="Component" :key="route.path" view-prop="value"/>
        </template>
        <template #fallback>
          <div id="FullSizeLoader">
            <img src="assets/images/loader.gif" class="responsive-img">
          </div>
        </template>
      </suspense>
    </transition>
  </router-view>
  <router-view name="footer" />
</template>

<script>
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {authStore} from "@/store/authStore";

export default {
  name: 'evently Dashboard',
  title: 'evently Dashboard',
  setup(){
    const config = configStore()
    const user = userStore()
    const auth = authStore()

    config.$subscribe((mutation, state) => {
      if(state.darkMode){
        localStorage.setItem('darkMode', JSON.stringify(1))
        document.getElementsByTagName('html')[0].setAttribute('class', 'dark')
      }else{
        localStorage.setItem('darkMode', JSON.stringify(0))
        document.getElementsByTagName('html')[0].removeAttribute('class')
      }
    })
    window.projectURL = config.projectURL
    return {config, user, auth}
  },
  data () {
    return {
    }
  },
  watch: {
  },
  mounted () {
    const _this = this
    //BEHEBUNG
    setInterval(()=>{
      _this.config.now = Date.now()
    },1000)
  },
  methods: {
  }
}
</script>

<style lang="css">
.ptr--icon,
.ptr--text {
 color: rgb(23 23 23 / 72%) !important;
}
body {
  height: 100vh !important;
}
.spaceTop {
  margin-top: calc(constant(safe-area-inset-top) + 54px) !important;
  margin-top: calc(env(safe-area-inset-top) + 54px) !important;
}
.sidebar {
  top: calc(constant(safe-area-inset-top) + 54px) !important;
  top: calc(env(safe-area-inset-top) + 54px) !important;
  z-index: 1001 !important;
}
.notchFix {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
.blurPage{
  /* Any browser which supports CSS3 */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 1000%;
  position: absolute;
  left: 0;
  top: 0;

  z-index: 99999;
  overflow:hidden;
}
.no-scroll{
  overflow: hidden;
}
.modal-mask {
  position: fixed;
  z-index: 1111;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  transition: all 0.3s ease;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.messenger-background {
  backdrop-filter: brightness(20%) blur(5px);
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
}
/*
.messenger-foreground {
  @media (min-width: 992px){
    max-width: 80% !important;
    max-height: 80% !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%) !important;
  }
  overflow: hidden;
}*/
.vueModal{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(20px) brightness(0.5);
}
.vueModalInner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
.sidebar-leave-active,
.sidebar-enter-active{
  transition: all 0.3s ease;
}

.sidebar-enter-from,
.sidebar-leave-to{
  left: -300px;
  opacity: 0;
}
.router-enter-active {
  transition: all 0.3s ease-out;
}

.router-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.router-enter-from,
.router-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
