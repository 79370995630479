<template>
  <main id="customerinfo">
    <div class="content-1400">
      <div class="pad-16">
        <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-orange">Übersicht</h2>
        <h1 style="padding-left: 4px;">Kundeninformationen</h1>
        <div class="pad-4">
          <div class="bgcolor-white pad-16 br-10">
            <div class="grid-12">
              <div class="col-sp-12 col-tb-6 row-sp-12">
                <h3 style="padding-top: 2px;">Übersicht</h3>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12">
                <form>
                  <div class="field">
                    <input type="search" placeholder="Suche nach Kunden...">
                  </div>
                </form>
              </div>
            </div>
            <customerItems v-for="citems in customerItems" :customerItems="citems" :key="citems.id"/>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import customerItems from "@/components/customerinfo/user";

export default {
  name: "Customerinfo",
  title: "Customerinfo",
  components: {
    customerItems: customerItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      eventItems: [],
    }
  },
  mounted() {
    this.config.loading = false
    this.getcustomerItems()
  },
  methods: {
    getcustomerItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('customerinfo/getcustomerItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.customerItems = response.data
            }
          })
    },
  }
}
</script>

<style>
  #customerinfo h1{
    font-size: 2em;
  }
  #customerinfo h3{
    font-size: 1.4em;
  }
  #customerinfo .field input{
    display: block;
    width: calc(100% - 24px);
    border-radius: 10px;
    border: none;
    background-color: rgba(0,0,0,0.05);
    padding: 12px;
    margin-bottom: 8px;
  }
</style>