<template>
  <main id="profil" class="content-1400">
    <div class="pad-4">
      <section id="profilheader">
        <div class="grid-12">
          <div class="col-sp-12 col-tb-3 col-sd-2 row-sp-12">
            <img :src="config.projectURL + 'assets/images/users/' + user.avatar" class="image width-150 circular"/>
          </div>
          <div class="col-sp-12 col-tb-9 col-sd-10 row-sp-12">
            <div class="profilheaderInfos">
              <div class="grid-12">
                <div class="col-sp-12 col-tb-8 row-sp-12 pad-16">
                  <h2 class="f-bold">{{ user.firstname }} {{ user.lastname }}</h2>
                  <h4 class="f-normal color-burlywood">{{ user.email }}</h4>
                </div>
                <div class="col-sp-12 col-tb-4 row-sp-12 t-right pad-16">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="grid-12">
      <div class="col-sp-12 col-sd-8 row-sp-12 pad-4">
        <section>
          <div class="bgcolor-white pad-16 br-10 t-left">
            <h3 style="padding-left: 8px; margin-bottom: 16px;">Dein Profil</h3>
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field pad-4">
                    <label>Vorname</label>
                    <input type="text" name="firstname" placeholder="Vorname" v-model="firstname" @keydown="status = ''" maxlength="20" required>
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field pad-4">
                    <label>Nachname</label>
                    <input type="text" name="lastname" placeholder="Nachname" v-model="lastname" @keydown="status = ''" maxlength="20" required>
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field pad-4">
                    <label>E-Mail</label>
                    <input type="email" name="email" placeholder="E-Mail" v-model="email" @keydown="status = ''" required>
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field pad-4">
                    <label>Profilbild</label>
                    <button class="button bgcolor-lightgrey color-black fluid" @click="avatarModal = true">Ändern</button>
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field pad-4">
                    <label>Passwort</label>
                    <input type="password" name="password" autocomplete="off" placeholder="Leer lassen, wenn keine Änderung" v-model="pw1" @keydown="status = ''">
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field pad-4">
                    <label>Passwort wiederholen</label>
                    <input type="password" name="password2" autocomplete="off" placeholder="Leer lassen, wenn keine Änderung" v-model="pw2" @keydown="status = ''">
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4">
                  <p class="feedbackmessage t-center pad-8 color-red" v-if="firstname.length >= 21 || lastname.length >= 21">
                    Dein Vor- oder Nachname sind zu lang.
                  </p>
                  <p class="feedbackmessage t-center pad-8 color-red" v-if="firstname.length == 0 || lastname.length == 0">
                    Vor- und Nachname sind Pflichtfelder
                  </p>
                  <p class="feedbackmessage t-center pad-8 color-red" v-if="status === 'pw' || pw1.length > 0 && pw2.length > 0 && pw1 != pw2 || pw1.length < 8 && pw2.length > 0">
                    Das Passwort ist nicht identisch oder kürzer als 8 Zeichen.
                  </p>
                  <p class="feedbackmessage t-center pad-8 color-red" v-else-if="status === 'error'">
                    Es ist ein Fehler aufgetreten, bitte versuche es erneut.
                  </p>
                  <p class="feedbackmessage t-center pad-8 color-green" v-else-if="status === 'ok'">
                    Die Änderungen wurden erfolgreich übernommen.
                  </p>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="pad-16" />
                  <div @click="deleteModal = true" class="color-orange" style="text-decoration: underline; cursor: pointer;">
                    Account löschen
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 t-right pad-4">
                  <div class="pad-16" />
                  <button class="button bgcolor-orange color-white" @click="edit">
                    Änderungen übernehmen
                  </button>
                </div>
              </div>
          </div>
        </section>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12 pad-4">
        <section id="myClubs">
          <div class="bgcolor-white pad-16 br-10 t-left">
            <h3>Deine Clubs</h3>
            <div>
              <clubItems v-for="citems in clubItems" :clubItems="citems" :key="citems.id"/>
            </div>
          </div>
        </section>
        <div class="pad-4"></div>
        <section id="helpcontainer" class="pad-4">
          <div class="bgcolor-white pad-16 br-10 t-center">
            <h4 class="color-second">Gibt es Probleme?</h4>
            <p class="color-orange">
              support@20north.de
            </p>
          </div>
        </section>
      </div>
    </div>
  </main>
  <div class="vueModal" v-if="deleteModal" style="position:fixed;">
    <div class="vueModalOuter" @click="deleteModal = false"/>
    <div class="vueModalInner">
      <div class="pad-16" />
      <div class="t-center pad-8">
        <h3>Möchtest du deinen Account wirklich löschen?</h3>
      </div>
      <div class="pad-16 t-center">
        <button @click="deleteModal = false" class="button bgcolor-second color-white" style="margin: 0 8px 8px 8px;">
          Abbrechen
        </button>
        <button type="button" @click="deleteAccount" class="button bgcolor-orange color-white">
          Account löschen
        </button>
      </div>
    </div>
  </div>
  <VueModal :show="avatarModal" @close="avatarModal = false" :approve="avatarBlob ? (loadingAvatar? 'lädt...' : 'speichern') : false" :approveDisabled="loadingAvatar" @approve="saveAvatar">
    <cropper-upload :init="true" :ratio="1" @cropped="c => this.avatarBlob = c" />
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import clubItems from "@/components/profil/club";
import CropperUpload from "@/components/images/cropperUpload";


export default {
  name: "Profil",
  title: "Profil",
  components: {
    clubItems: clubItems,
    CropperUpload
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      firstname: this.user.firstname,
      lastname: this.user.lastname,
      email: this.user.email,
      pw1: '',
      pw2: '',
      deleteModal: false,
      status: '',
      clubItems: '',
      avatarModal: false,
      avatarBlob: false,
      loadingAvatar: false
    }
  },
  mounted() {
    this.config.loading = false
    this.getClubItems()
  },
  methods: {
    saveAvatar(){
      this.loadingAvatar = true
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('avatar', this.avatarBlob)
      this.axios.post(this.config.projectURL + 'profil/changeAvatar', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            console.log(response.data, response.status)
            if(response.status === 250){
              _this.user.avatar = response.data.avatar
              _this.avatarModal = false;
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            _this.loadingAvatar = false
          })
    },
    edit(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('firstname', this.firstname)
      fD.append('lastname', this.lastname)
      fD.append('email', this.email)
      fD.append('password', this.pw1)
      fD.append('password2', this.pw2)
      this.axios.post(this.config.projectURL + 'profil/edit', fD)
          .then(response => {
            if(response.status === 250){
              this.status = 'ok'
              const data = response.data
              this.user.email = data.email
              this.user.firstname = data.firstname
              this.user.lastname = data.lastname
            }else if(response.status === 251){
              this.status = 'pw'
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    deleteAccount(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'profil/deleteAccount', fD)
          .then(response => {
            console.log(response)
            if(response.status === 250) this.auth.logout()
          })
          .catch(e => {console.error(e)})
    },
    getClubItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('profil/getMyClubs', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.clubItems = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
  }
}
</script>

<style>

main#profil{
  padding-bottom: 80px;
  padding-top: 120px!important;
}
#profilheader{
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
}
#profilheader img{
  border: 4px solid var(--second-bg-color);
  position: relative;
  z-index: 1;
  margin-left: 32px;
  margin-top: -80px;
}
#profilheader .profilheaderInfos{
  text-align: left;
  padding-bottom: 16px;

}
#profilheader .profilheaderInfos h2{
  font-size: 2em;
  margin: 0;
}
#profilheader .profilheaderInfos h4{
  font-size: 1.2em;
}
#helpcontainer p{
  margin-top: 8px;
  font-size: 0.9em;
}
#profil section h3{
  font-size: 1.4em;
}
#profil section .field label{
  display: block;
}
#profil section .field input{
  display: block;
  width: calc(100% - 24px);
  border-radius: 10px;
  border: none;
  background-color: rgba(0,0,0,0.05);
  padding: 12px;
  margin-bottom: 8px;
}
</style>