<template>
    <div id="ticketCenter" class="content-1400">
      <div class="pad-8">
        <div class="grid-12">
          <div class="col-sp-12 row-sp-12 pad-4">
            <h2 style="padding-left: 4px; font-size: 1.2em;" class="color-orange">Übersicht</h2>
            <h1>Ticketcenter</h1>
          </div>
          <div class="col-sp-12 col-tb-8 row-sp-12 pad-4">
            <form>
              <input type="search" id="ticketSearch" placeholder="Suche nach Tickets..." v-model="searchQuery">
            </form>
            <div class="bgcolor-white br-10 pad-16" id="ticketResults">
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12">
                  <h3>Ticket-Varianten</h3>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 t-right">
                  <router-link :to="{name: 'TicketCreate'}" class="button bgcolor-orange color-white">Ticket-Variante erstellen</router-link>
                </div>
              </div>
              <div>
                <ticketItems v-for="titems in filteredTickets" :ticketItems="titems" :key="titems.id"/>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-tb-4 row-sp-12 pad-4">
            <div class="bgcolor-white br-10 pad-16" id="statsBox">
              <div class="grid-12">
                <div class="col-sp-12 row-sp-12">
                  <h3>Statistik</h3>
                </div>
                <div class="col-sp-12 row-sp-12">
                  <h4>Tickets verkauft:</h4>
                </div>
                <div class="col-sp-12 row-sp-12 t-right">
                  <h4>{{ soldTicketsCount }}</h4>
                </div>
                <div class="col-sp-12 row-sp-12">
                  <h4>Gesamt-Umsatz:</h4>
                </div>
                <div class="col-sp-12 row-sp-12 t-right">
                  <h4>{{ soldTicketsTurnover.toFixed(2) }}€</h4>
                </div>
              </div>
            </div>
            <div class="pad-4"></div>
            <div class="bgcolor-orange pad-16 br-10">
              <h3 style="padding-left: 4px; font-size: 1.2em;" class="color-white">Promotion - Erreiche mehr Menschen</h3>
              <h4 style="padding-left: 4px; padding-top: 4px; margin-top: -4px; font-size: 0.9em; margin-bottom: 8px; color: rgba(255,255,255,0.7)">Möchtest du mehr Tickets verkaufen?</h4>
              <div class="t-right">
                <router-link to="/promotions" class="button bgcolor-second color-white">Promotion erstellen</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import ticketItems from "@/components/ticketcenter/ticket";

export default {
  name: "Ticketcenter",
  title: "Ticketcenter",
  components: {
    ticketItems: ticketItems,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      ticketItems: [],
      soldTicketsCount: 0,
      soldTicketsTurnover: 0,
      searchQuery: ''
    }
  },
  mounted() {
    this.config.loading = false
    this.getTicketItems()
    this.getAllSoldTickets()
  },
  computed: {
    filteredTickets(){
      const _this = this
      return this.ticketItems.filter(i => {
        return i.title.toLowerCase().includes(_this.searchQuery.toLowerCase()) || i.ticketTitle.toLowerCase().includes(_this.searchQuery.toLowerCase())
      })
    }
  },
  methods: {
    getTicketItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('ticketcenter/getTicketItems', fD)
          .then((response) => {
            if(response.status === 250){
              _this.ticketItems = response.data
            }
          })
    },
    getAllSoldTickets(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('ticketcenter/getAllSoldTickets', fD)
          .then((response) => {
            if(response.status === 250){
              _this.soldTicketsCount = response.data.length
              response.data.forEach((item, index) => {
                _this.soldTicketsTurnover = _this.soldTicketsTurnover + Number(item.buyPrice)
              })
            }
          })
    },

  }
}
</script>

<style scoped>
#ticketCenter{
  margin-top: 16px;
}
#ticketCenter h1{
  font-size: 2em;
}
#ticketSearch{
  padding: 12px;
  border-radius: 10px;
  border: none;
  font-size: 1em;
  margin-bottom: 16px;
  width: 100%;
}
#ticketResults h3,
#statsBox h3{
  font-size: 1.6em;
  margin-bottom: 16px;
  font-weight: 800;
}
#statsBox h4{
  font-size: 1.1em;
}
#statsBox .t-right h4{
  font-weight: 400;
  font-size: 1.5em;
  margin-bottom: 16px;
}
</style>