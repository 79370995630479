<template>
  <article class="event" @click="$router.push({name: 'EventEdit', params: { id: eventItems.id }});">
    <div class="grid-12">
      <div class="col-sp-4 row-sp-12">
        <img class="eventImage" :src="config.projectURL + 'assets/images/events/'+eventItems.id+'/title.jpg'"/>
      </div>
      <div class="col-sp-8 row-sp-12 eventContent">
        <div class="label tiny button bgcolor-orange color-white">{{ eventItems.label }}</div>
        <h4>{{ eventItems.title }}</h4>
        <h5>{{ eventItems.subTitle }}</h5>
        <h6>{{this.covertStartTime}}</h6>
      </div>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "eventItems",
  props: ["eventItems"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      covertStartTime: '',
    }
  },
  mounted() {
    this.config.loading = false
    this.covertTime()
  },
  methods: {
    covertTime(){
      const _this = this
      let timestampArr = this.eventItems.startTime.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      _this.covertStartTime = dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    },
  }
}
</script>

<style scoped>
.event{
  margin-top: 16px;
  cursor: pointer;
}
.event .label{
  padding: 4px 8px;
  text-transform: none;
  font-size: 0.7em;
  margin-top: 4px;
}
.event .eventContent{
  padding-left: 8px;
}
.event .eventImage{
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  border-radius: 10px;
  border: 2px solid #ffffff;
  background-color: rgba(0,0,0,0.06);
  min-height: 80px;
}
.event .eventContent h4{
  font-size: 1.1em;
  font-family: 'Helvetica';
  font-weight: 600;
  margin-bottom: -2px;
}
.event .eventContent h5{
  font-size: 0.95em;
  font-family: 'Helvetica';
  font-weight: 300;
}
.event .eventContent h6{
  font-size: 0.9em;
  font-family: 'Helvetica';
  font-weight: 300;
}
</style>