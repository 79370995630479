<template>
  <header>
    <div class="content-1400">
      <div class="grid-12">
      <div class="col-sp-6 row-sp-12 pad-8">
        <ul>
          <li>
            <router-link to="home">
              Home
            </router-link>
          </li>
          <li>
            <router-link to="home">
              Über uns
            </router-link>
          </li>
          <li>
            <router-link to="home">
              Preise
            </router-link>
          </li>
          <li>
            <router-link to="home">
              Features
            </router-link>
          </li>
          <li>
            <router-link to="home">
              Kontakt
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-sp-6 row-sp-12 pad-8 t-right">
        <router-link to="dashboard" v-if="auth.isAuth">
          <img :src="'assets/images/users/'+user.avatar" class="image width-40 circular"/>
        </router-link>
        <router-link class="button bgcolor-orange color-white" to="login" v-if="!auth.isAuth">
          Login
        </router-link>
        <router-link style="margin-left: 8px;" class="button bgcolor-second color-white" to="register" v-if="!auth.isAuth">
          Registrieren
        </router-link>
      </div>
    </div>
    </div>
  </header>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "PublicNavBar",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {

    }
  },
  mounted() {
  }
}
</script>

<style scoped>
header{
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0px;
  padding-top: 8px;
}
header ul{
  padding: 0;
  margin: 8px 0 0 16px;
}
header ul li{
  list-style: none;
  display: inline;
  margin-right: 16px;
}
header ul li a{
  color: rgba(0,0,0,0.8);
  text-decoration: none;
  font-size: 1.1em;
  transition: 0.3s;
}
header ul li a:hover{
  color: rgba(0,0,0,0.4);
}

</style>